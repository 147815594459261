<template>
  <div class="pay-fail-container">
    <div class="fail-container">
      <div class="fail-box">
        <img :src="imgSrc" alt="" style="width: 100%;height: 100%;">
      </div>
      <div class="cancel">
        <p style="font-size: 20px;margin: 20px 0 10px 0;color: green;">用户取消支付</p>
<!--        <p style="font-size: 25px;margin: 10px 0px 15px 0;">111.00元</p>-->

        <div style="margin: 10px 0;border-top: 1px dashed gray;padding-top: 15px;">
          <span>交易时间:</span>
          <span>{{ time }}&nbsp;&nbsp;{{time1}}</span>
        </div>
        <div style="margin: 0 0 20px 0;">
          <span>订单号:</span>
          <span>{{orderId}}</span>
        </div>

      </div>
      <button @click.stop="handleClick">返回首页</button>
    </div>
  </div>
</template>

<script>
import util from "@/util/util";

export default {
  name: "payFail",
  data() {
    return {
      imgSrc: require("@/assets/img/cancelPay.png"),
      time: null,
      time1: null,
      orderId: '',
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      let query = this.$route.query;
      this.orderId = query.orderId;
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth();
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
      let min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
      this.time = `${year}-${month}-${day}`;
      this.time1 = `${hour}:${min}`;
    },
    handleClick() {
      let type = util.getData("action")===0?"/":'/addOil';
      this.$router.push({
        name: "addOil",
        path: type,

      })
    }
  }
}
</script>

<style scoped lang="less">
.pay-fail-container {
  width: 100%;
  background-color: #eee;
  .fail-container {
    width: 80%;
    //height: 100vh;
    margin: 10px auto;
   background: #fff;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0 10px -6px #000;
    .fail-box {
      margin-top: 30px;
      width: 60px;
      height: 60px;

    }

    p {
      text-align: center;
    }

    button {
      width: 100px;
      height: 55px;
      border: 2px solid green;
      border-radius: 7px;
      color: green;
      white-space: nowrap;
      background-color: #fff;
    }
  }


}
</style>